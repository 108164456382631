import { useColorMode, Switch, Stack, IconButton, Icon } from '@chakra-ui/react'
import { MoonIcon, SunIcon } from '@chakra-ui/icons'

export const ColorModeSwitch = () => {
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === 'dark'

  return (
    <Stack direction="row" spacing={3}>
      {isDark ? (
        <MoonIcon boxSize="1.25em" opacity={0.85} />
      ) : (
        <SunIcon boxSize="1.25em" opacity={0.85} />
      )}
      <Switch color="green" isChecked={isDark} onChange={toggleColorMode} />
    </Stack>
  )
}
