import { Box, Flex, Text, Icon, Heading } from '@chakra-ui/react'
import Logo from '@app/components/Logo'
import Link from 'next/link'
import { ColorModeSwitch } from './ColorModeSwitch'

const Header = ({ compact = false }: { compact?: boolean }) => {
  return (
    <Flex
      as="header"
      alignItems="center"
      justifyContent={compact ? 'flex-start' : 'center'}
      py={compact ? 0 : 10}
      px={compact ? 0 : 4}
      position="relative"
    >
      <Heading as="h1" fontSize={compact ? '36px' : '48px'} py="5">
        <Link href="/" passHref>
          <a>
            <Logo />
          </a>
        </Link>
      </Heading>
      <Flex
        position="absolute"
        height="100%"
        right="0"
        top="0"
        py="5"
        justifyContent="center"
        alignItems={{
          base: compact ? 'center' : 'flex-start',
          md: 'flex-start',
        }}
      >
        <ColorModeSwitch />
      </Flex>
    </Flex>
  )
}

export default Header
