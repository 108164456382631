import qs from 'query-string'
import { RateLimit } from 'async-sema'

export type OpenSeaAsset = {
  id: number
  image_url: string
  name: string
  token_id: string
  permalink: string
  owner: OpenSeaProfile
  collection: {
    name: string
    image_url: string
    slug: string
  }
  asset_contract: {
    address: string
    schema_name: string
  }
}

export type SimpleHashAsset = {
  nft_id: string
  previews: {
    image_small_url: string
    image_medium_url: string
    image_large_url: string
  }
  name: string
  token_id: string
  collection: {
    name: string
    image_url: string
    marketplace_pages: { marketplace_name: string; collection_url: string }[]
  }
  contract: {
    type: string
  }
}

export type OpenSeaEvent = {
  id: number
  event_timestamp: string
  listing_time: string
  payment_token: {
    symbol: string
  }
  total_price: string
  winner_account: OpenSeaProfile
  dev_seller_fee_basis_points: number
  seller: OpenSeaProfile
  transaction: {
    block_number: string
    transaction_hash: string
    transaction_index: string
  }
}

export type SimpleHashEvent = {
  timestamp: string
  from_address: string
  to_address: string
  transaction: string
  block_number: number
  sale_details: {
    marketplace_name: string
    is_bundle_sale: boolean
    payment_token: {
      symbol: string
    }
    unit_price: number
    total_price: number
  }
}

export type EtherscanTransaction = {
  hash: string
  input: string
  from: string
  to: string
  gas: string
  gasPrice: string
  gasUsed: string
  timeStamp: string
  isError: string
  blockNumber: string
}

export type InfuraTransaction = {
  hash: string
  from: string
  to: string
  input: string
  maxFeePerGas: string
  maxPriorityFeePerGas: string
  blockNumber: string
  gas: string
  gasPrice: string
}

export type InfuraTransactionReceipt = {
  gasUsed: string
  effectiveGasPrice: string
}

export type InfuraBlocks = {
  baseFeePerGas: string[]
  gasUsedratio: number[]
  oldestBlock: string
}

export type OpenSeaProfile = {
  address: string
  profile_img_url: string
  user: {
    username: null | string
  }
}

const openSeaPublicRateLimit = RateLimit(4)

export const etherscanFetch = async (
  params: Record<string, string | number | undefined>,
) => {
  return fetch(`
		https://api.etherscan.io/api?${qs.stringify({
      ...params,
      apikey: process.env.ETHERSCAN_API_KEY,
    })}
	`).then(async (res) => {
    const json = await res.json()
    if (json.status === '0' && json.result.startsWith('Max rate limit')) {
      throw new Error('ratelimit_etherscan')
    }
    return json
  })
}

const openseaFetch = async (path: string) => {
  if (!process.env.OPENSEA_API_KEY) await openSeaPublicRateLimit()
  return fetch(`https://api.opensea.io/api/v1/${path}`, {
    headers: {
      accept: 'application/json',
      ...(process.env.OPENSEA_API_KEY
        ? { 'x-api-key': process.env.OPENSEA_API_KEY! }
        : {}),
    },
  }).then((res) => res.json())
}

const simpleHashFetch = async (path: string, fetchParams?: RequestInit) => {
  return fetch(`https://api.simplehash.com/api/v0/${path}`, {
    ...fetchParams,
    headers: {
      accept: 'application/json',
      'x-api-key': process.env.SIMPLEHASH_API_KEY!,
      ...(fetchParams?.headers || {}),
    },
  }).then(async (res) => {
    const json = await res.json()
    if (res.status === 429) {
      throw new Error('ratelimit_simplehash')
    }
    return json
  })
}

const infuraFetch = (method: string, params: (string | string[])[]) => {
  return fetch(`https://mainnet.infura.io/v3/${process.env.INFURA_API_KEY}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      jsonrpc: '2.0',
      method,
      params,
      id: '1',
    }),
  }).then((res) => res.json())
}

export const fetchOpenSeaAsset = ({
  chain,
  contractAddress,
  tokenId,
}: {
  chain: string
  contractAddress: string
  tokenId: string
}): Promise<OpenSeaAsset> => {
  return openseaFetch(`asset/${contractAddress}/${tokenId}`).then((json) => {
    if (!json.id) throw new Error('Failed fetching OpenSea asset')
    return json
  })
}

export const fetchSimpleHashAsset = ({
  chain,
  contractAddress,
  tokenId,
}: {
  chain: string
  contractAddress: string
  tokenId: string
}): Promise<SimpleHashAsset> => {
  return simpleHashFetch(`nfts/${chain}/${contractAddress}/${tokenId}`)
}

export const triggerSimpleHashAssetRefresh = ({
  chain,
  contractAddress,
  tokenId,
}: {
  chain: string
  contractAddress: string
  tokenId: string
}) => {
  return simpleHashFetch(
    `nfts/refresh/${chain}/${contractAddress}/${tokenId}`,
    { method: 'POST' },
  )
}

export const fetchSales = ({
  chain,
  contractAddress,
  tokenId,
}: {
  chain: string
  contractAddress: string
  tokenId: string
}): Promise<SimpleHashEvent[]> => {
  return simpleHashFetch(
    `nfts/transfers/${chain}/${contractAddress}/${tokenId}?order_by=timestamp_desc`,
  ).then((json) => {
    return json.transfers.filter(({ sale_details }: SimpleHashEvent) =>
      Boolean(sale_details),
    )
  })
}

let profileCache: Record<string, OpenSeaProfile> = {}
let profilePromiseCache: Record<string, Promise<OpenSeaProfile>> = {}

export const getCachedProfile = (address: string) =>
  profileCache[address] || null

export const fetchProfile = (
  address: string,
): Promise<OpenSeaProfile | null> => {
  if (profileCache[address]) return Promise.resolve(profileCache[address])
  if (!profilePromiseCache[address]) {
    profilePromiseCache[address] = openseaFetch(`user/${address}`).then(
      (json) => {
        profileCache[address] = json.account
        return json.account
      },
    )
  }
  return profilePromiseCache[address]
}

export const fetchContractTransactions = ({
  contractAddress,
  startBlock,
  endBlock,
}: {
  contractAddress: string
  startBlock?: number
  endBlock?: number
}): Promise<EtherscanTransaction[]> => {
  return etherscanFetch({
    module: 'account',
    action: 'txlist',
    startblock: startBlock,
    endBlock: endBlock,
    address: contractAddress,
  }).then((json) => json.result)
}

export const fetchTransaction = (hash: string): Promise<InfuraTransaction> => {
  return infuraFetch('eth_getTransactionByHash', [hash]).then(
    (json) => json.result,
  )
}

export const fetchTransactionReceipt = (
  hash: string,
): Promise<InfuraTransactionReceipt> => {
  return infuraFetch('eth_getTransactionReceipt', [hash]).then(
    (json) => json.result,
  )
}

export const fetchInternalTransactions = (txhash: string) => {
  return etherscanFetch({
    module: 'account',
    action: 'txlistinternal',
    txhash,
  }).then((json) => json.result)
}

export const fetchBlocks = (
  blockCount: number,
  endBlock: number,
): Promise<InfuraBlocks> => {
  return infuraFetch('eth_feeHistory', [
    `0x${blockCount.toString(16)}`,
    `0x${endBlock.toString(16)}`,
    [],
  ]).then((json) => json.result)
}
