import { Text, Flex } from '@chakra-ui/react'
import EthereumIcon from '@app/components/EthereumIcon'
import { readableEthValue } from '@app/utils/ether'

const Price = ({
  value,
  fontSize = '1em',
  tokenSymbol,
  colorMode,
}: {
  value: number
  fontSize?: React.ComponentProps<typeof Text>['fontSize']
  tokenSymbol: string
  colorMode?: React.ComponentProps<typeof EthereumIcon>['colorModeOverride']
}) => {
  return (
    <Flex
      alignItems="center"
      fontSize={fontSize}
      as="span"
      display="inline-flex"
      verticalAlign="middle"
    >
      <EthereumIcon wrapped={tokenSymbol === 'WETH'} colorMode={colorMode} />
      <Text fontWeight="700" my="0" as="span" display="inline-block">
        {readableEthValue(value)}
      </Text>
    </Flex>
  )
}

export default Price
