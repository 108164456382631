import { useState } from 'react'
import { fetchProfile, getCachedProfile, OpenSeaProfile } from '@app/utils/api'
import { Flex, Link, Text, Image, Icon, Spinner } from '@chakra-ui/react'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { BiWallet } from 'react-icons/bi'
import { MdOutlineAccountCircle } from 'react-icons/md'
import { useEffect } from 'react'
import { Account as AccountType } from '@app/utils/snipe'

const Account = ({
  address,
  profileInfo,
  withLink = true,
}: {
  address: string
  profileInfo?: AccountType
  withLink?: boolean
}) => {
  const [loadedProfileInfo, setLoadedProfileInfo] = useState<
    AccountType | undefined | null
  >(() => {
    if (profileInfo) return profileInfo
    const cached = getCachedProfile(address)
    if (cached) {
      return {
        address: cached.address,
        name: cached.user?.username || null,
        imageUrl: cached.profile_img_url || null,
      }
    }
    return undefined
  })

  const [loadingProfile, setLoadingProfile] = useState(false)

  const abbreviatedAddress = address.slice(2, 8)
  useEffect(() => {
    if (loadedProfileInfo !== undefined || loadingProfile) return
    ;(async () => {
      setLoadingProfile(true)
      const account = await fetchProfile(address)
      setLoadedProfileInfo(
        account
          ? {
              address: account.address,
              name: account.user?.username || null,
              imageUrl: account.profile_img_url || null,
            }
          : null,
      )
      setLoadingProfile(false)
    })()
  }, [loadedProfileInfo, address, loadingProfile])

  return (
    <Flex
      alignItems="center"
      display="inline-flex"
      {...(withLink && {
        as: Link,
        target: '_blank',
        href: `https://opensea.io/${address}`,
      })}
    >
      {loadedProfileInfo?.imageUrl ? (
        <Image
          alt={loadedProfileInfo?.name || abbreviatedAddress}
          src={loadedProfileInfo.imageUrl}
          width="22px"
          height="22px"
          borderRadius="full"
          mr="0.35em"
        />
      ) : (
        <Icon
          as={HiOutlineUserCircle}
          position="relative"
          mr="0.15em"
          fontSize="1.2em"
        />
      )}
      <Text
        as="span"
        maxW="10em"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {loadedProfileInfo?.name || abbreviatedAddress}
      </Text>
      {loadingProfile ? <Spinner ml="2" size="xs" /> : null}
    </Flex>
  )
}

export default Account
